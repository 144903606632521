<template>
  <!-- eslint-disable vue/max-attributes-per-line -->
  <div>
    <CustomTable :items="items" :fields="fields" />
  </div>
  <!-- <div v-else>
    <no-data />
  </div> -->
</template>

<script>
/* eslint-disable array-callback-return */
import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'

export default {
  mixins: [general],
  data() {
    return {
      fields: [
        { key: 'avatar', label: 'Avatar' },
        { key: 'warehouse_name', label: 'Product name' },
        { key: 'sku', label: 'sku' },
        // { key: 'price', label: 'Selling Price', sortable: true },
        { key: 'stock', label: 'Stock', sortable: true },
        { key: 'sold', label: 'Sold', sortable: true },
        { key: 'is_active', label: 'Status' },
        { key: 'is_warehouse', label: 'Warehouse' },
        { key: 'updated_at_utc', label: 'Lasted update', sortable: true },
        // { key: 'more', label: 'Tool', sortable: false },
      ],
      items: [],
    }
  },
  mounted() {
    this.loadList()
  },
  methods: {
    async loadList() {
      const siteId = JSON.parse(localStorage.getItem('siteID')).id
      try {
        const res = await Request.get(
          this.$http,
          `${process.env.VUE_APP_API_URL}/warehouses?site_id=${siteId}&count_page=100&sort[]=created_at,desc`,
        )
        if (res.status === 200) {
          if (res.data.status) {
            this.perPage = res.data.data.count_page
            this.currentPage = res.data.data.current_page
            this.totalRows = res.data.data.items.length

            const array = []
            res.data.data.items.map(val => {
              if (!array.find(attName => attName.name === val.name)) {
                array.push(val)
              }
            })
            array.map(val => {
              const findData = res.data.data.items.filter(
                ({ name }) => name === val.name,
              )
              const item = {
                id: val.id,
                product_id: val.product_id,
                avatar: val.avatar,
                warehouse_name: val.name,
                warehouse_id: val.id,
                sku: val.sku,
                price: val.price,
                is_active: val.is_active,
                is_warehouse: val.is_warehouse,
                updated_at: val.updated_at,
                updated_at_utc: val.updated_at,
                // stock: 20,
                // sold: 5,
              }
              let sumStock = 0
              let sumSold = 0
              findData.map(y => {
                sumStock += y.stock
                sumSold += y.sold
                item.stock = sumStock
                item.sold = sumSold
              })
              this.items.push(item)
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Opps! Something wrong',
                icon: 'AlertOctagonIcon',
                variant: 'danger',
                text: String(this.showError(res.data.error, ',')),
              },
            })
          }
        }
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Opps! Something wrong',
            icon: 'AlertOctagonIcon',
            variant: 'danger',
            text: String(error),
          },
        })
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.format-text
  width: 200px

  &__title
    width: 100%
    text-overflow: ellipsis
    overflow: hidden
    -webkit-line-clamp: 3
    -webkit-box-orient: vertical
    display: -webkit-box
    white-space: pre-wrap
</style>
